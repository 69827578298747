<template>
  <div>
    <header class="page-header">
      <el-row :gutter="20" align="middle">
        <el-col :span="12">
          <h2>Захиалгын жагсаалт</h2>
        </el-col>
        <el-col :span="12">
          <div class="actions text-right">
            <router-link to="/add-order">
              <el-button type="success" class="item">Нэмэх</el-button>
            </router-link>
          </div>
        </el-col>
      </el-row>
    </header>
    <div class="panel tabled">
      <el-tabs v-model="activeTabStatus" @tab-click="onClickTab">
        <el-tab-pane :label="tab.label" :name="tab.value" v-for="(tab, indexTab) in tabData" :key="indexTab">
          <div class="panel-content">
            <div class="pl20 pr20 table-filter mb10">
              <el-row :gutter="10" align="middle" type="flex">
                <el-col :span="20">
                  <el-input placeholder="Хайх" class="input-with-select" v-model="search" @input="onSearch">
                    <el-button slot="prepend" icon="el-icon-search"></el-button>
                  </el-input>
                </el-col>
                <el-col :span="4" :offset="4" class="text-right">
                  <el-dropdown  @command="handleSort">
                  <el-button type="default" class="lowercase">Эрэмбэлэх <i class="el-icon-sort"></i></el-button>
                      <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item :command = 'filter.value' v-for="(filter, indexFilter) in filterData" :key="indexFilter">
                              {{filter.label}}
                           </el-dropdown-item>
                       </el-dropdown-menu>
                  </el-dropdown>
                </el-col>
              </el-row>
            </div>
            <order-table :search='search' :orders="orders" :drivers='drivers' :loading="loading" :pageSize='pageSize' :totalCount="totalCount" :currentPage="currentPage" :sizeChangeHandler="sizeChangeHandler" :curentPageHandler="curentPageHandler"></order-table>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import services from '../../../helpers/services'
import OrderTable from './components/orderTable'
export default {
  name: 'orderList',
  components: {
    OrderTable
  },
  data () {
    return {
      pay_status: '',
      orders: [],
      loading: false,
      activeTabStatus: 'paid',
      currentPage: 1,
      pageSize: 20,
      order_status: '',
      pageFrom: 0,
      search: '',
      totalCount: 0,
      from: '',
      size: '',
      drivers: [],
      filterData: [
        {
          label: 'Нэр А - Я',
          value: 'firstChar'
        }, {
          label: 'Нэр Я - А',
          value: 'lastChar'
        }, {
          label: 'Шинэ эхэнд',
          value: 'newest'
        }, {
          label: 'Хуучин эхэнд',
          value: 'oldest'
        }
      ],
      tabData: [
        {
          label: 'Төлсөн',
          value: 'paid'
        }, {
          label: 'Төлөөгүй',
          value: 'not paid'
        }, {
          label: 'Амжилттай',
          value: 'success'
        }, {
          label: 'Цуцлагдсан',
          value: 'canceled'
        }, {
          label: 'Бүгд',
          value: 'all'
        }
      ]
    }
  },

  mounted () {
    var from = (this.pageSize * (this.currentPage - 1))
    var size = this.pageSize
    if (this.$route.query.page && this.$route.query.size && this.$route.query.search && this.$route.query.tab) {
      const incomingPage = this.$route.query.page ? parseFloat(this.$route.query.page) : 0
      const incomingSize = parseFloat(this.$route.query.size)
      const incomingSearch = this.$route.query.search
      const incomingTab = this.$route.query.tab
      this.currentPage = incomingPage
      this.pageSize = incomingSize
      this.search = incomingSearch
      this.tab = incomingTab
      from = (this.pageSize * (incomingPage - 1))
      size = incomingSize
    }
    this.from = from
    this.size = size
    this.pay_status = 1
    this.getOrders(from, size, this.search)
  },

  methods: {
    onSearch () {
      this.$router.push({ name: 'orderList', query: { page: this.currentPage, size: this.pageSize, search: this.search, tab: this.activeTabStatus } })
      this.getOrders((this.pageSize * (this.currentPage - 1)), this.pageSize, this.search, this.activeTabStatus)
    },

    sizeChangeHandler (item) {
      this.pageSize = item
      this.$router.push({ name: 'orderList', query: { page: this.currentPage, size: this.pageSize, search: this.search, tab: this.activeTabStatus } })
      this.getOrders((this.pageSize * (this.currentPage - 1)), this.pageSize, this.search, this.activeTabStatus)
    },

    curentPageHandler (item) {
      this.currentPage = item
      this.$router.push({ name: 'orderList', query: { page: item, size: this.pageSize, search: this.search, tab: this.activeTabStatus } })
      this.getOrders((this.pageSize * (this.currentPage - 1)), this.pageSize, this.search, this.activeTabStatus)
    },

    sortMethods (method, data) {
      if (method === 'firstChar') {
        data.sort((a, b) => a.title.localeCompare(b.title))
      } else if (method === 'lastChar') {
        data.sort((a, b) => b.title.localeCompare(a.title))
      } else if (method === 'newest') {
        data.sort((a, b) => b.createdAt.localeCompare(a.createdAt))
      } else if (method === 'oldest') {
        data.sort((a, b) => a.createdAt.localeCompare(b.createdAt))
      }
      return data
    },

    handleSort (data) {
      this.search = ''
      this.orders = this.sortMethods(data, this.orders)
    },

    getOrders (from, size, search) {
      this.loading = true
      const query = '?pay_status=' + this.pay_status + '&search_text=' + search + '&from=' + from + '&size=' + size + '&order_status=' + this.order_status
      services.getOrders(query).then(response => {
        this.loading = false
        if (response.status === 'success') {
          this.orders = response.orders
          this.totalCount = response.total
          const tempDrivers = []
          response.orders.forEach(element => {
            if (element.driver_name && element.driver_name !== null) {
              tempDrivers.push({ text: element.driver_name, value: element.driver_name })
            }
          })
          this.drivers = tempDrivers
        }
      })
    },
    onClickTab (data) {
      if (data.label === 'Бүгд') {
        this.pay_status = ''
        this.order_status = ''
      } else if (data.label === 'Амжилттай') {
        this.order_status = 100
        this.pay_status = ''
      } else if (data.label === 'Төлөөгүй') {
        this.pay_status = 0
        this.order_status = ''
      } else if (data.label === 'Төлсөн') {
        this.pay_status = 1
        this.order_status = ''
      } else if (data.label === 'Цуцлагдсан') {
        this.order_status = 91
        this.pay_status = ''
      }
      this.getOrders(this.from, this.size, this.search)
    }
  }
}
</script>
