<template>
  <div>
    <!-- Product list table ued zaaval product-table gesen class uguh -->
    <el-table
      v-loading="loading"
      :data="orders"
      style="width: 100%"
      @cell-click="clickCell"
    >
      <el-table-column label="Дугаар" prop="order_number" width="100px">
        <template slot-scope="scope">
          <strong>{{scope.row.order_number}}</strong>
        </template>
      </el-table-column>
      <el-table-column label="Статус"  width="200px">
        <div slot-scope="scope" style="color:seagreen;" v-if="scope.row.order_status === 'Хадгалсан'">
         <strong> {{(scope.row.order_status)}}</strong>
        </div>
         <div slot-scope="scope" style="color:darkred;" v-else-if="scope.row.order_status === 'Төлбөр төлөөгүй'">
         <strong> {{(scope.row.order_status)}}</strong>
        </div>
        <div slot-scope="scope" style="color:#F5A30A;" v-else-if="scope.row.order_status === 'Төлбөр төлсөн'">
         <strong> {{(scope.row.order_status)}}</strong>
        </div>
        <div slot-scope="scope" style="color:blue;" v-else-if="scope.row.order_status === 'Auto татан авалт үүссэн'">
         <strong> {{(scope.row.order_status)}}</strong>
        </div>
        <div slot-scope="scope" style="color:purple;" v-else-if="scope.row.order_status === 'Очиж авах'">
         <strong> {{(scope.row.order_status)}}</strong>
        </div>
        <div slot-scope="scope" style="color:chocolate;" v-else-if="scope.row.order_status === 'Урьдчилсан захиалга'">
         <strong> {{(scope.row.order_status)}}</strong>
        </div>
         <div slot-scope="scope" style="color:mediumseagreen;" v-else-if="scope.row.order_status === 'Баталгаажсан'">
         <strong> {{(scope.row.order_status)}}</strong>
        </div>
        <div slot-scope="scope" style="color:olivedrab;" v-else-if="scope.row.order_status === 'Харилцагч хүлээн авсан'">
         <strong> {{(scope.row.order_status)}}</strong>
        </div>
        <div slot-scope="scope" style="color:yellowgreen;" v-else-if="scope.row.order_status === 'Бэлэн болсон'">
         <strong> {{(scope.row.order_status)}}</strong>
        </div>
        <div slot-scope="scope" style="color:gold;" v-else-if="scope.row.order_status === 'Жолоочид хуваарилсан'">
         <strong> {{(scope.row.order_status)}}</strong>
        </div>
        <div slot-scope="scope" style="color:teal;" v-else-if="scope.row.order_status === 'Хүргэлтэнд гарсан'">
         <strong> {{(scope.row.order_status)}}</strong>
        </div>
        <div slot-scope="scope" style="color:navy;" v-else-if="scope.row.order_status === 'Очсон'">
         <strong> {{(scope.row.order_status)}}</strong>
        </div>
        <div slot-scope="scope" style="color:aqua;" v-else-if="scope.row.order_status === 'Салбарлуу'">
         <strong> {{(scope.row.order_status)}}</strong>
        </div>
        <div slot-scope="scope" style="color:red;" v-else-if="scope.row.order_status === 'Цуцалсан'">
         <strong> {{(scope.row.order_status)}}</strong>
        </div>
        <div slot-scope="scope" style="color:red;" v-else-if="scope.row.order_status === 'Цуцалсан СС'">
         <strong> {{(scope.row.order_status)}}</strong>
        </div>
        <div slot-scope="scope" style="color:green;" v-else-if="scope.row.order_status === 'Амжилттай'">
         <strong> {{(scope.row.order_status)}}</strong>
        </div>
        <div slot-scope="scope" style="color:red;" v-else-if="scope.row.order_status === 'Буруу утга'">
         <strong> {{(scope.row.order_status)}}</strong>
        </div>
        <div slot-scope="scope" style="color:blue;" v-else-if="scope.row.order_status === 'Хадгалсан GO'">
         <strong> {{(scope.row.order_status)}}</strong>
        </div>
         <div slot-scope="scope" style="color:green;" v-else-if="scope.row.order_status === 'Төлбөр төлсөн GO'">
         <strong> {{(scope.row.order_status)}}</strong>
        </div>
         <div slot-scope="scope" style="color:red;" v-else-if="scope.row.order_status === 'Цуцалсан GOA'">
         <strong> {{(scope.row.order_status)}}</strong>
        </div>
         <div slot-scope="scope" style="color:blue;" v-else-if="scope.row.order_status === 'Үлдэгдэл шалгасан'">
         <strong> {{(scope.row.order_status)}}</strong>
        </div>
      </el-table-column>
      <el-table-column label="Захиалсан огноо" width="180px">
        <template slot-scope="scope">
          {{formatDate(scope.row.createdAt)}}
        </template>
      </el-table-column>
      <el-table-column prop="user_phone" label="Хэрэглэгч" width="120px">
      </el-table-column>
      <el-table-column prop="pay_status" label="Төлбөр" width="120">
      </el-table-column>
      <el-table-column
          label="Жолооч"
          width="120"
          prop="driver_name"
          :filters="drivers"
          :filter-method="filterHandler"
        >
    </el-table-column>
      <el-table-column label="Нийт үнэ">
        <template slot-scope="scope">
          {{generateCurrency(scope.row.pay_total)}}
        </template>
      </el-table-column>
      <el-table-column label="Тоо ширхэг">
        <template slot-scope="scope">
          {{scope.row.OrderItems.length}} ш
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change = "curentPageHandler"
      @size-change = "sizeChangeHandler"
      class="text-right mt10"
      background
      :current-page.sync="incomingPage"
      :page-size="pageSize"
      :page-sizes="[5, 20, 50, 100]"
      layout="total, sizes, prev, pager, next"
      :total="totalCount"
    >
    </el-pagination>
  </div>
</template>
<script>
import helper from '../../../../helpers/helper'
import services from '../../../../helpers/services'
export default {
  name: 'orderTable',
  props: [
    'orders',
    'pageSize',
    'currentPage',
    'loading',
    'totalCount',
    'curentPageHandler',
    'sizeChangeHandler',
    'search',
    'drivers'
  ],
  data () {
    return {
      isloading: false,
      orderdriver: [],
      incomingPage: 1
    }
  },
  // watch: {
  //   currentPage (value) {
  //     this.page = value
  //   }
  // },
  mounted () {
    var from = (this.pageSize * (this.currentPage - 1))
    var size = this.pageSize
    if (this.$route.query.page && this.$route.query.size) {
      const incomingPage = parseFloat(this.$route.query.page)
      const incomingSize = parseFloat(this.$route.query.size)
      this.currentPage = incomingPage
      this.pageSize = incomingSize
      from = (this.pageSize * (incomingPage - 1))
      size = incomingSize
    }
    this.from = from
    this.size = size
    this.orderDrivers(from, size)
  },
  methods: {
    formatDate (val) {
      return helper.formatDate(val)
    },
    filterHandler (value, row) {
      return row.driver_name === value
    },
    generateCurrency (val) {
      return helper.generateCurrency(parseFloat(val))
    },
    // tabClicked () {
    //   this.orderDrivers
    // },
    orderDrivers (from, size) {
      this.isloading = true
      const query = '?pay_status=' + this.pay_status + '&search_text=' + this.search + '&from=' + from + '&size=' + size + '&order_status=' + this.order_status
      services.orderDrivers(query).then(response => {
        if (response.status === 'success') {
          this.orderdriver = response.orders[0]
          this.isloading = false
        }
      })
    },
    clickCell (row, column, cell, event) {
      this.$router.push(
        {
          name: 'orderDetail',
          params: {
            id: row.id
          }
        }
      )
    }
  }
}
</script>
